import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MichaelImg from "../components/michael-img"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className="container">
      <div className="row">
        <div className="col s12">
          <h1>About</h1>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h3 className="about-title">Imprint Entertainment</h3>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m3 offset-m1 push-m8 center-align"></div>
        <div className="col s12 m8 pull-m4">
          <p className="main-content tagline">
            An American media company founded in 2008 and headquartered in Los
            Angeles, California. Specializing in film and television production
            for all forms of distribution, along with music publishing for
            scores, soundtracks and artists.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <h3 className="about-title">Michael Becker</h3>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m3 offset-m1 push-m8 center-align">
          <MichaelImg />
        </div>
        <div className="col s12 m8 pull-m4">
          <p className="main-content tagline">
            Michael Becker has over 20 years of experience in the entertainment
            industry as a driving force behind creative talent and producing
            distinguished entertainment for film and television. 
            <br />
            <br />
            Michael began his career as a talent agent in 1999, representing
            writers, directors and producers. He later took over as head of film
            and television at Datner Dispoto and Associates, where he added
            cinematographers to his client roster. As an agent, Michael
            represented his clients in many high profile deals such as Sex and
            the City, I, Robot, The Notebook, Nip/Tuck, Panic Room, The West
            Wing and many more. After leaving the agency business, Michael went
            into management to work with a variety of clients including music
            artists Soulja Boy, Pitbull, DJ Quik and others. 
            <br />
            <br />
            In 2008, Michael segued into producing and formed Imprint
            Entertainment, where he is currently CEO. At Imprint, Michael
            specializes in all aspects of the entertainment business, which
            include: development, packaging, financing, production, and music
            publishing for film and television.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default About

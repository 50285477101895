import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const MichaelImg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Michael_Becker.JPG" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      className="headshot"
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Michael Becker"
      loading="eager"
    />
  )
}

export default MichaelImg
